var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zt-page-content"},[(_vm.item)?_c('div',{staticClass:"zt-block",staticStyle:{"height":"calc(100% - 107px)","overflow":"hidden"}},[_c('div',{staticClass:"item"},[_c('el-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('el-row',[_c('h3',{staticClass:"font14-grey ellipsis"},[_vm._v(" 需求名称:  "+_vm._s(_vm.item.title)+" ")]),(_vm.item[_vm.item.type] && _vm.item[_vm.item.type].cat)?_c('p',{staticStyle:{"color":"#b3b3b3","margin-bottom":"0"}},[_vm._v(" "+_vm._s(_vm.item[_vm.item.type].cat.name)+" ")]):_vm._e()]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.goto('/want/wantFollow/want/detail/' + _vm.$route.params.id)}}},[_vm._v("查看需求详情")])],1)],1),(_vm.list && _vm.list.length > 0)?_c('div',{staticClass:"list",staticStyle:{"height":"calc(100% - 100px)","overflow":"auto"}},_vm._l((_vm.list),function(item1,idx){return _c('div',{key:idx,staticClass:"list-item flex",staticStyle:{"min-width":"1000px"}},[_c('div',{staticStyle:{"width":"300px","cursor":"pointer"}},[_c('div',{staticClass:"flex",on:{"click":function($event){return _vm.goto(
                '/want/wantFollow/pipe/detail/' +
                  _vm.$route.params.id +
                  '/' +
                  item1.id
              )}}},[_c('div',{staticStyle:{"width":"106px","height":"106px","margin-right":"10px"}},[(item1.comp && item1.comp.logo)?_c('div',{staticClass:"bg_img",style:({
                  backgroundImage:
                    'url(' + _vm.imgUrl + item1.comp.logo + '!width_250px' + ')',
                })}):_c('div',{staticClass:"bg_img",style:({
                  backgroundImage:
                    'url(' +
                    _vm.staticUrl +
                    'design/placeholder/zhanweilogo%402x.jpg!width_250px' +
                    ')',
                })})]),(item1.comp && item1.comp.name)?_c('div',{staticClass:"flex-1 flex-justify-between",staticStyle:{"text-align":"left","position":"relative"}},[_c('h3',{staticClass:"font14-grey ellipsis1"},[_vm._v(_vm._s(item1.comp.name))]),_c('el-button',{staticStyle:{"color":"#5074ee","position":"absolute","bottom":"0","left":"0"},attrs:{"type":"text"},on:{"click":function($event){$event.stopPropagation();return _vm.viewIntro(item1.comp.id)}}},[_vm._v("查看公司详情资质 >")])],1):_vm._e()])]),_c('div',{staticClass:"list-item-tr",staticStyle:{"min-width":"300px","max-width":"350px"}},[_c('div',{staticClass:"font14-grey1"},[_vm._v("出价")]),_c('div',[_vm._v("￥"+_vm._s(_vm._f("priceFilt")(item1[_vm.item.type].price)))])]),(item1[_vm.item.type].giveDate)?_c('div',{staticClass:"list-item-tr"},[_c('div',{staticClass:"font14-grey1"},[_vm._v("最早交货")]),_c('div',[_vm._v(_vm._s(_vm._f("moment")(item1[_vm.item.type].giveDate,"YYYY-MM-DD")))])]):_vm._e(),_c('div',{staticClass:"list-item-tr flex-1",staticStyle:{"padding":"10px 30px"}},[(_vm.item.type != 'dayang' && _vm.item.type != 'gaikuang')?_c('el-button',{attrs:{"size":"mini","type":"primary","round":""},on:{"click":function($event){return _vm.viewProduct(item1)}}},[_vm._v("查看商品")]):_vm._e(),_c('el-button',{attrs:{"size":"mini","type":"text"},on:{"click":function($event){return _vm.goto(
                '/want/wantFollow/pipe/detail/' +
                  _vm.$route.params.id +
                  '/' +
                  item1.id +
                  '?type=' +
                  [_vm.item.type]
              )}}},[_vm._v(" 查看应答 ")]),(item1.orderId)?_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.viewOrder}},[_vm._v("查看需求订单")]):_c('el-button',{attrs:{"size":"mini","type":"primary","round":""},on:{"click":function($event){return _vm.goOrder(item1)}}},[_vm._v("生成订单并签约")])],1)])}),0):_vm._e(),(_vm.list && _vm.list.length == 0)?_c('div',{staticStyle:{"text-align":"center","padding-top":"80px"}},[_c('img',{staticStyle:{"width":"170px"},attrs:{"src":_vm.staticUrl + 'design/placeholder/zhanwuyingda.png',"alt":""}}),_c('p',{staticStyle:{"font-size":"18px","color":"#cccccc","margin-top":"15px"}},[_vm._v(" 暂无应答 ")])]):_vm._e()]):_vm._e(),_c('div',{staticStyle:{"padding":"7px"}},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next,total","current-page":_vm.queryOpt.page.pageNumber,"page-size":_vm.queryOpt.page.pageCount,"total":_vm.total},on:{"update:currentPage":function($event){return _vm.$set(_vm.queryOpt.page, "pageNumber", $event)},"update:current-page":function($event){return _vm.$set(_vm.queryOpt.page, "pageNumber", $event)}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }